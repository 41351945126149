/* global require, up */

// DEPRECATED

// use carousel-container compiler instead
up.compiler("slide-show", function(element, data){
  let interval_length = up.element.numberAttr(element, "data-interval") ?? 20000;
  let slides = up.util.toArray(up.element.all(element, "slide-item")).shuffle().map((e) => {up.element.hide(e); return e});
  let current_slide = slides.shift();

  function show_next_slide() {
    up.element.hide(current_slide);
    slides.push(current_slide)
    current_slide = slides.shift()
    up.element.show(current_slide)
  }

  let timeout_id = setInterval(show_next_slide, interval_length); // Change image every 2 seconds

  up.element.show(element);
  up.element.show(current_slide);

  return function(){
    clearInterval(timeout_id);
  };
})
