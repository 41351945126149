/* global up */

const {invoke} = require("../lib/utils")

// unpoly custom configs
up.form.config.submitSelectors.push(["form"]);
up.link.config.followSelectors.push("a[href]");
up.link.config.instantSelectors.push("a[href]");
//active: for semantic-ui (active means too many things in semantic, not sure if this is useful...
up.feedback.config.currentClasses.push("active");

// for this to work the script tags need to be inside the up-target. in 3.2 maybe this is changed (there are doc changes related to this)
// up.fragment.config.runScripts=true

// ---------------------------------------- fixing unpoly stuff ----------------------------------------

var selector_closest_fixed = false;
(function fix_selector_closest(){
  if(!selector_closest_fixed){
    selector_closest_fixed = true
    up.Selector.prototype.closest = function(element) {
      var parentElement;
      let child_target = element.querySelector(this.unionSelector);
      if (child_target){
        return child_target
      }
      else if (this.matches(element)) {
        return element;
      } else if ((parentElement = element.parentElement)) {
        return this.closest(parentElement);
      }
    };
  }
})();

// seems like this is still broken in unpoly 2.0
// maybe fixed in unpoly 3.0

/*
  Add [formaction] attribute to buttons or other things in a form.
  form respects it's child [formaction]
 */
up.compiler("[formaction]", function(element, data) {
  const form_id = element?.attributes?.form?.value
  const form = element.closest("form") ?? document.getElementById(form_id);

  if (! form ) {
    console.log("Form not found for element", element)
  }

  const formaction = element.getAttribute("formaction");
  const target     = element.getAttribute("up-target");
  const layer      = element.getAttribute("up-layer");

  return up.on(element, "click", function(event){
    let submit_options = {url: formaction};
    if (target) {submit_options.target = target}
    if (layer)  {submit_options.layer = layer}
    up.event.halt(event);
    if(form.checkValidity()){
        up.submit(form, submit_options);
    }
    else {
      form.reportValidity();
    }
  })
})


// -------------------- auto disable form submit buttons --------------------

up.on("up:form:submit", function(event, form) {
  let submitButton = event?.submitButton;

  function enable_button_fn(event, element, data) {
    submitButton.disabled = false;
    up.off(form, "up:fragment:loaded:reply", enable_button_fn);
  };

  if (submitButton){
    submitButton.disabled = true;
    up.on(form, "up:fragment:loaded:reply", enable_button_fn);
  }
});

// FIXME: make a proper dialog interaction that doesn"t require full page reload
// and only reload when something in the dialog changed
// keeping track of changes may be done via context and hooking into form submit events
// example:
// on-dismiss="reload"
// reload-target="#header, #section"
// up.on("up:layer:dismissed", (event) => {
//   const origin = event?.layer?.origin
//   const on_dismiss = up.element.attr(origin, "on-dismiss")
//   const reload_target = up.element.attr(origin, "reload-target")
//   if(on_dismiss == "reload"){
//     if(reload_target){
//       up.reload(reload_target)
//     }
//     else{
//       up.reload()
//     }
//   }
// })

// FIXME: when many forms are submitted quickly some will remain in the up-active state forever
// form [up-active] class-name customization
up.compiler("[up-active]", function(element, data){
  const active_class = up.element.attr(element, "up-active")
  //const input = up.element.get(element, "input");
  //const form = element.form;
  const form = element.parentElement.querySelector("form") ?? up.element.closest(element, "form");
  const min_duration = up.element.numberAttr(element,"up-active-duration") ?? 1000;
  let start_timestamp;

  if (!form){
    console.error("coudn't find form for [up-active] compiler", element, data);
    return;
  }

  if(!active_class){
    console.error("coudn't find class name for [up-active] element", element, data);
    console.error("use up-active=\"<class_name>\"");
    return;
  }

  const submit_handler = up.on(form, "up:form:submit", function(event, form) {
    start_timestamp = event.timeStamp
    element.classList.add(active_class);
  })

  // seems like this is failing sometimes
  // maybe there is a race condition here?
  const reply_handler = up.on(form, "up:fragment:loaded:reply", function(event, form) {
    let end_timestamp = event.timeStamp
    let event_duration = end_timestamp - start_timestamp
    let makeup_duration = min_duration - event_duration;
    up.util.timer(makeup_duration, function(){
      element.classList.remove(active_class)
    })
  })

  let cleanup = [
    submit_handler,
    reply_handler
  ];

  return function(){
    cleanup.forEach(invoke)
  };
});

// -------------------- elements/forms that made requests get a callback when requests is done --------------------

up.on("up:fragment:loaded", function(event) {
  // fire event back to origin (sorta acts like a callback)
  // console.log ("up:fragment:loaded", event)
  if (event?.origin) {
    up.emit(event.origin, "up:fragment:loaded:reply", event);
  }
});


up.compiler("[console-dump]", function(element){
  return up.on(element, "data-dump", function(event){
    console.log(JSON.parse(element.querySelector("object").innerHTML))
  })
})
